import { BASE_LOCALE } from "~/utilities/constants/LOCALES";

export const formatPrice = (
  value: number | string | null | undefined,
  abbreviate?: boolean
): string => {
  if (!value) return "0.00";

  let numericValue: number;

  if (typeof value === "string") {
    numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  } else {
    numericValue = value;
  }
  if (numericValue > 100000000 && abbreviate) {
    return abbreviateNumber(numericValue);
  }

  const formatOptions =
    numericValue >= 10000
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 2, maximumFractionDigits: 2 };

  return new Intl.NumberFormat(BASE_LOCALE, formatOptions).format(numericValue);
};

export const formatPercentage = (
  value: number | string | undefined | null
): string => {
  if (value === undefined || value === null) return "0.0";

  let numericValue: number;

  if (typeof value === "string") {
    numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  } else {
    numericValue = value;
  }
  if (numericValue >= 100) {
    return "100";
  }

  return numericValue.toFixed(1);
};

export const formatCurrency = (value: number, currency: string) => {
  return new Intl.NumberFormat(BASE_LOCALE, {
    style: "currency",
    currency,
    currencyDisplay: "narrowSymbol",
  }).format(value);
};

export const abbreviateNumber = (
  num: number | string | null | undefined
): string => {
  if (!num) return "0";
  if (typeof num === "string") {
    num = parseFloat(num);
  }

  if (num >= 1e12) {
    return (num / 1e12).toFixed(1).replace(/\.0$/, "") + "T"; // Trillions
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
  }

  return num.toString();
};
